<template>
    <div id="app">
        <nav>
            <router-link active-class="active" to="/">Home</router-link>
            <router-link active-class="active" to="/tasks">Tasks</router-link>
            <router-link active-class="active" to="/units">Units</router-link>
        </nav>
        <router-view />
    </div>
</template>

<style>
/* Roboto google font */
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");

* {
    font-family: "Roboto", sans-serif;
}

#app {
    /* Center horizontally */
    margin: 0 auto;
    margin-top: 0.5rem;
    max-width: 800px;
    height: 98vh;
    padding-left: 1rem;
    padding-right: 1rem;
}

nav {
    margin-bottom: 1rem;
    display: flex;

    /* Center horizontally with gap */
    justify-content: center;
    gap: 1rem;
}

#app h2 {
    text-align: center;
}

nav a {
    /* Style like a button */
    text-decoration: none;
    padding: 0.5rem;
    color: black;
    border: 1px solid gray;
    border-radius: 0.25rem;
    background-color: white;
}

nav a:hover,
nav a:focus {
    background-color: lightgray;
}

nav a.active {
    font-weight: bold;
    background-color: darkgray;
}
</style>

<script>
export default {
    name: "App",
};
</script>
