import { createRouter, createWebHistory } from 'vue-router'



const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: () => import('./components/HomePage.vue') },
        { path: '/tasks', component: () => import('./components/TasksList.vue') },
        { path: '/units', component: () => import('./components/UnitsTable.vue') },
    ],
})

export default router
